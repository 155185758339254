import { Row, Col } from "react-bootstrap";
import { Chart, RadialLinearScale, ArcElement, Tooltip, Legend } from "chart.js";
import CustomCard from "../components/CustomCard.js";
import EqualCategory from "../components/EqualCategory";

Chart.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const Result = ({ resultData, onBack, rawData }) => {
  const score = resultData.reduce((score, factor) => score + factor.weight * (factor.value - 1), 0);

  return (
    <>
      {/* <Card>
      <Card.Body>
        <h1>Result</h1>
        <Button variant="primary" className="mb-2" onClick={onBack}>
          Back
        </Button>
        <br />
        <ButtonGroup>
          <ToggleButton
            id="1"
            type="radio"
            variant="outline-success"
            name="equalFactor"
            value="equalFactor"
            checked={type === "equalFactor"}
            onChange={(e) => setType(e.currentTarget.value)}
          >
            Equal Factor
          </ToggleButton>
          <ToggleButton
            id="2"
            type="radio"
            variant="outline-success"
            name="equalCategory"
            value="equalCategory"
            checked={type === "equalCategory"}
            onChange={(e) => setType(e.currentTarget.value)}
          >
            Equal Category
          </ToggleButton>
        </ButtonGroup>
        <EqualCategory resultData={resultData} />
      </Card.Body>
    </Card> */}
      <CustomCard header={`Total Suitability Score: ${score.toFixed(1)}%`} onBack={onBack} />
      <CustomCard title="Chart">
        <EqualCategory resultData={resultData} />
      </CustomCard>
      <CustomCard title="Category Matrix">
        <Row>
          {["---", ...rawData.categories].map((cat2, cat2Index) => (
            <Col key={`category-category-${cat2Index}`}>{cat2}</Col>
          ))}
        </Row>
        {rawData.categories.map((cat1, categoryIndex) => (
          <Row key={`category-${categoryIndex}`}>
            <>
              <Col>{cat1}</Col>
              {rawData.compareCategories[categoryIndex].map((col, colIndex) => (
                <Col key={`category-${categoryIndex}-col-${colIndex}`}>{col}</Col>
              ))}
            </>
          </Row>
        ))}
      </CustomCard>
      {rawData.categories.map((category, categoryIndex) => (
        <CustomCard key={`customCard-${categoryIndex}`} title={`${category} Factors Matrix`}>
          <Row>
            {["---", ...rawData.factors[categoryIndex]].map((factor, factorIndex) => (
              <Col key={`factorTitle-${factorIndex}`}>{factor}</Col>
            ))}
          </Row>
          {rawData.factors[categoryIndex].map((factor1, factorIndex1) => (
            <Row key={`factor-${factorIndex1}`}>
              <>
                <Col>{factor1}</Col>
                {rawData.compareFactors[categoryIndex][factorIndex1].map((col, colIndex) => (
                  <Col key={`factor-${factorIndex1}-col-${colIndex}`}>{col}</Col>
                ))}
              </>
            </Row>
          ))}
        </CustomCard>
      ))}
    </>
  );
};

export default Result;
